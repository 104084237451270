import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-inline-styles/no-inline-styles */
import { Handle, Position } from "@xyflow/react";
export const ChargeStationNode = (props) => {
    const isConnectionLabel = props.data.label.toLowerCase().includes("connection");
    const heightClass = isConnectionLabel ? "h-32" : "h-64";
    const isLabelContainSite = props.data.label.toLowerCase().includes("site");
    const topClass = isLabelContainSite ? "top-[-60px]" : "top-[-105px]";
    return (_jsxs(_Fragment, { children: [_jsx(Handle, { "data-test": "source-handle", isConnectable: false, position: Position.Left, type: "target" }), _jsx("div", { className: `border-solid border-2 rounded border-gray-800 w-24 ${heightClass} text-center content-start network-grey text-black box-border`, children: _jsx("div", { className: `w-full text-center text-xl relative font-bold ${topClass}`, children: props.data.label }) }), _jsx(Handle, { "data-test": "target-handle", isConnectable: false, position: Position.Right, type: "source" })] }));
};
