import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sankey, sankeyCenter, sankeyLinkHorizontal } from "d3-sankey";
import { useEffect, useRef, useState } from "react";
import { getSankeyNodeColor } from "../../pages/Projects/ProjectDetails.helper";
const MARGIN_Y = 20;
const MARGIN_X = 20;
const width = 1100;
const height = 550;
const TWENTY_FIVE = 25;
const SankeyChart = ({ data, projectId, projectName }) => {
    const [hoveredNode, setHoveredNode] = useState(null);
    const [tooltip, setTooltip] = useState(null);
    const svgRef = useRef(null);
    const [dimensions, setDimensions] = useState({ height, width });
    const containerRef = useRef(null);
    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { height, width } = containerRef.current.getBoundingClientRect();
                setDimensions({ height, width });
            }
        };
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const sankeyGenerator = sankey()
        .nodeWidth(TWENTY_FIVE)
        .nodePadding(TWENTY_FIVE)
        .extent([
        [MARGIN_X, MARGIN_Y],
        [width - MARGIN_X, height - MARGIN_Y]
    ])
        .nodeId((node) => node.id)
        .nodeAlign(sankeyCenter);
    const { links, nodes } = sankeyGenerator(data);
    const isLinkHighLighted = (link) => {
        return hoveredNode === link.source.index || hoveredNode === link.target.index;
    };
    const getLinkOpacity = (link) => {
        const DEFAULT_VALUE = 0.4;
        const HIGHLIGHT = 0.6;
        const BACKGROUND = 0.1;
        if (hoveredNode === null)
            return DEFAULT_VALUE;
        return isLinkHighLighted(link) ? HIGHLIGHT : BACKGROUND;
    };
    const handleMouseMove = (event, content) => {
        if (svgRef.current) {
            const svgBounds = svgRef.current.getBoundingClientRect();
            const xScale = dimensions.width / svgBounds.width;
            const yScale = dimensions.height / svgBounds.height;
            const mouseX = (event.clientX - svgBounds.left) * xScale;
            const mouseY = (event.clientY - svgBounds.top) * yScale;
            const offset = 10; // Offset from the cursor
            const maxWidth = 200;
            let x = mouseX + offset;
            const y = mouseY + offset;
            // If tooltip would extend beyond the right edge, position it to the left of the cursor
            if (x + maxWidth > dimensions.width) {
                // Assume max tooltip width of 200px
                x = mouseX - offset - maxWidth;
            }
            setTooltip({ content, x, y });
        }
    };
    const getConnectionText = (projectName, projectId, id) => {
        return (projectName === "Hansweert" &&
            projectId === "308a84bb-fc3a-442e-92c9-5508086385f0" &&
            id === "WATTHUB") ||
            (projectName === "Amsterdam" && projectId === "d85bd393-6cc3-4461-bcf0-78365d5bbf12" && id === "WATTHUB")
            ? "Grid Connection"
            : id;
    };
    const handleNodeHover = (event, node) => {
        const text = getConnectionText(projectName, projectId, node.id);
        setHoveredNode(node.index);
        handleMouseMove(event, `ID: ${text}\nCharge Energy: ${getIncomingSummary(node)}\nDischarge Energy: ${getOutgoingSummary(node)}`);
    };
    const handleLinkHover = (event, link) => {
        const sourceText = getConnectionText(projectName, projectId, link.source.id);
        handleMouseMove(event, `Source: ${sourceText}\nTarget: ${link.target.id}\nValue: ${formatTooltipValues(link.value)}`);
    };
    const handleMouseLeave = () => {
        setHoveredNode(null);
        setTooltip(null);
    };
    const allNodes = nodes.map((node) => {
        node.x0 = node.xPos;
        node.x1 = node.xPos + sankeyGenerator.nodeWidth();
        const text = getConnectionText(projectName, projectId, node.id);
        return (_jsxs("g", { children: [_jsx("rect", { fill: getSankeyNodeColor(node.type), fillOpacity: 1, height: node.y1 - node.y0, onMouseEnter: (e) => handleNodeHover(e, node), onMouseLeave: () => handleMouseLeave(), rx: 0.9, stroke: "black", width: sankeyGenerator.nodeWidth(), x: node.x0, y: node.y0 }), node.y1 - node.y0 > 0 && (_jsx("text", { dy: "0.35rem", fontSize: 14, textAnchor: node.x0 < width / 2 ? "start" : "end", x: node.xPos < width / 2 ? node.x1 + 6 : node.xPos - 6, y: (node.y1 + node.y0) / 2, children: getNodeLabel(text) }))] }, node.index));
    });
    const allLinks = links.map((link) => {
        const linkGenerator = sankeyLinkHorizontal();
        const path = linkGenerator(link);
        return (_jsx("g", { children: _jsx("path", { d: path, fill: "none", onMouseEnter: (e) => handleLinkHover(e, link), onMouseLeave: () => handleMouseLeave(), stroke: getSankeyNodeColor(link.source.type), strokeOpacity: getLinkOpacity(link), strokeWidth: link.width }, link.index) }, link.index));
    });
    return (_jsxs("div", { className: "sankey-component", ref: containerRef, children: [_jsxs("svg", { height: "100%", preserveAspectRatio: "xMidYMid meet", ref: svgRef, viewBox: "0 0 1100 550", width: "100%", children: [allNodes, allLinks] }), tooltip && (_jsx("div", { className: "sankey-tooltip", style: {
                    left: `${tooltip.x}px`,
                    top: `${tooltip.y}px`
                }, children: tooltip.content }))] }));
};
const getIncomingSummary = (node) => {
    let incoming = 0;
    node.targetLinks.forEach((link) => {
        incoming = incoming + link.value;
    });
    return formatTooltipValues(incoming);
};
const getOutgoingSummary = (node) => {
    let incoming = 0;
    node.sourceLinks.forEach((link) => {
        incoming = incoming + link.value;
    });
    return formatTooltipValues(incoming);
};
const formatTooltipValues = (value) => {
    if (value < 1)
        return "No Data";
    return `${Math.round(value)} kWh`;
};
const getNodeLabel = (value) => {
    if (value.includes("|")) {
        const values = value.split("|");
        return values[0].concat(" & more");
    }
    return value;
};
export default SankeyChart;
