import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatIconInfo } from "blocks-react/bedrock/components/Icons/Info/Info";
import { CatModal } from "blocks-react/bedrock/components/Modal/Modal";
import { format } from "date-fns";
import { getBatteryIcon } from "../../lib/getBatteryIcon";
import { getAssetImageIcon, getLocalTime, isLastReportedTimeStale } from "../../pages/Projects/ProjectDetails.helper";
const ChargingOpsPlannerModal = (props) => {
    const { assetDetails, heading, onCloseModal, projectMetaData, showModal } = props;
    const reportingStatus = isLastReportedTimeStale(assetDetails?.timestamp) ? "Not Reporting" : "Reporting";
    return (_jsxs(CatModal, { "data-test": "chargingOpsPlanner-modal", disableBackdropClick: true, heading: heading, headingVariant: "title", isActive: showModal, onBlModalClose: () => {
            onCloseModal();
        }, size: "lg", children: [" ", _jsx("div", { className: "p-4", children: _jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "w-2/5 flex flex-row items-center", children: [_jsx("img", { alt: "Assets", className: "w-10 h-10 mr-4", "data-test": `${assetDetails?.assetSerialNumber}-image`, src: getAssetImageIcon(assetDetails) }), _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { children: _jsx("div", { className: "font-bold", children: assetDetails.assetSourceId }) }), _jsxs("div", { children: [assetDetails?.assetMetadata?.serialNumber, " | ", assetDetails?.assetMetadata?.make, " |", " ", assetDetails?.assetMetadata?.model] }), _jsxs("div", { children: ["Last update:", " ", format(getLocalTime(assetDetails?.timestamp, projectMetaData?.offset), "dd MMM yyy HH:mm")] })] })] }), _jsxs("div", { className: "w-2/5 flex flex-col", children: [_jsxs("div", { className: "flex", children: ["Reporting Status:", _jsxs("div", { className: "flex ml-[5px]", children: [_jsx("span", { className: "font-bold", children: reportingStatus }), _jsx("div", { className: "w-2.5 h-2.5 rounded-full mr-1 bg-[#1AC69E] ml-[3px] mt-[8px]" }), _jsx(CatIconInfo, { className: "inline-flex justify-center mt-[6px] h-3.5", slot: "trigger" })] })] }), _jsxs("div", { className: "flex", children: ["Activity: ", _jsx("div", { className: "font-bold ml-[5px]", children: assetDetails?.activity }), _jsx(CatIconInfo, { className: "inline-flex justify-center pl-1 h-3.5", slot: "trigger" })] }), _jsxs("div", { className: "flex", children: ["Project Name: ", _jsx("div", { className: "font-bold ml-[5px]", children: projectMetaData?.projectName })] })] }), _jsx("div", { className: "w-1/5 flex flex-col items-end justify-center", children: _jsxs("div", { className: "flex", children: [_jsxs("div", { className: "font-bold mr-[5px]", children: ["SoC: ", Math.round(assetDetails?.soc), "%"] }), getBatteryIcon(Number(assetDetails?.soc))] }) })] }) })] }));
};
export default ChargingOpsPlannerModal;
